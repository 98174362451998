<template>
  <div>
    <b-modal
        id="editarUsuario"
        hide-footer
        centered
        size="xl"
        title="Editar Usuário Externo"
    >
      <b-card>

        <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
          <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
            {{ error[0] }}
          </div>
          <div class="alert-body">{{ getErrors.message }}</div>
        </b-alert>

        <validation-observer ref="novoUsuarioValidation">
          <b-form class="mt-2">
            <b-row>

              <b-col sm="6">
                <b-form-group label="Nome"  label-for="account-username" >
                  <validation-provider #default="{ errors }"  name="Nome" rules="required|min:3">
                    <b-form-input  v-model="form.name" :state="errors.length > 0 ? false:null" name="nome"  placeholder="Nome do Usuário" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group  :label="form.email_verified_at ? 'E-mail já verificado não pode ser editado' : 'E-mail'"  label-for="account-e-mail" >
                  <validation-provider #default="{ errors }"  name="Email" rules="required|email">
                    <b-form-input v-model="form.email" :disabled="form.email_verified_at ? true : false" :state="errors.length > 0 ? false:null" name="email" placeholder="Email"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Tipo Usuário" label-for="account-tipo">
                  <validation-provider #default="{ errors }"   name="Tipo Usuário" rules="required">
                    <b-form-select  v-model="form.tipo_usuario" :options="tipo_usuario"  :state="errors.length > 0 ? false:null" text-field="nome"  value-field="id"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-alert
            variant="primary"
            show
        >
          <div class="alert-body">
            <span><strong>Obs.:</strong> Usuários Externos só terão acesso a tela de Pedidos e somente nos serviços autorizados para esse usuário no momento do cadastro! </span>
          </div>
        </b-alert>

      </b-card>

      <b-row align-h="end">
        <b-col cols="auto" class="mb-3">
          <b-button type="submit" class="mt-2 mr-1" variant="primary" :disabled="submit" @click.prevent="editarUsuario">
            <b-spinner small v-if="spinner" />
            <span v-if="spinner">Aguarde...</span>
            <span v-else>Gravar Alterações</span>
          </b-button>
          <b-button  class="mt-2" type="reset" variant="outline-secondary" @click="$bvModal.hide('editarUsuario')">Fechar </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>




</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BModal,
  BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody,
  BLink, BImg,BFormSelect, BCardHeader, BCardTitle, BFormCheckbox,BTable,BSpinner,BFormSelectOption
} from 'bootstrap-vue';


import VueAvatar from './src/components/VueAvatar.vue'
import VueAvatarScale from './src/components/VueAvatarScale.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import Permissoes from './permissoes'
import { mapState } from 'vuex'

export default {
  props: ['editar'],
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTable,
    BSpinner,
    BFormSelectOption,
    VueAvatar,
    VueAvatarScale,
    ValidationProvider,
    ValidationObserver,


  },
  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      required,
      email,
      boxTwo: '',
      errors:[],
      spinner: false,
      submit: false,
      tipo_usuario: [
        { id: '', nome: 'Selecione o Tipo do Usuário'},
        { id: 2, nome: 'Radiologia'},
        { id: 3, nome: 'Projetos CAD'},
        { id: 4, nome: 'Outros'},
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.editar
      },
      set(new_val) {
        this.$emit('editar', new_val)
      }
    }
  },
  methods: {

    editarUsuario(){
      this.$emit('editar', this.form)

      this.$refs.novoUsuarioValidation.validate().then(success => {
        if(success){
          this.spinner = true
          this.submit = true

          this.$http.put('odontodocs/usuarios/'+this.form.id, this.form).then(res => {

            this.$bvModal.hide('editarUsuario')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário cadastrado com sucesso! um email de confirmação foi enviado',
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
            this.form = {
              url: '',
              name: '',
              email: '',
              tipo_usuario: ''
            }
            this.$refs.novoUsuarioValidation.reset();
          })
              .catch(error => {

                let res = this.$responseMessages.getMessage(error, null)
                this.getErrors = res

              }).finally( () => {
            this.spinner = false
            this.submit = false
          })

        }
      } )
    },

  },

}
</script>
