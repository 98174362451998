<template>
  <b-row>
    <b-col md="6" sm="4" class="my-1" >
<!--      <b-button variant="gradient-success" @click="$bvModal.show('adicionarUsuario')" >
        <feather-icon class="mr-50" icon="UserPlusIcon" />
        <span class="align-middle">Adicionar Usuário</span>
      </b-button>-->
    </b-col>
    <b-col md="6" sm="8" class="my-1">
      <b-form-group label-cols-sm="3" label-align-sm="right" label-size="lg" label-for="filterInput" class="mb-0" >
        <b-input-group size="sm">
          <b-form-input id="filterInput"  v-model="filter" type="search" placeholder="Procurar"  />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Limpar </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show="">
      <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
        {{ error[0] }}
      </div>
      <div class="alert-body">{{ getErrors.message }}</div>
    </b-alert>
    <b-col cols="12">
      <b-table striped hover responsive :items="users" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
               :filter="filter" :filter-included-fields="filterOn"
      >

        <template #cell(url)="data">
          <b-avatar :src="data.value.length ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${data.value}` : '' " />
        </template>

        <template #cell(tipo_usuario)="data">
          <b-badge :variant="permissions[1][data.value]">
            {{ permissions[0][data.value] }}
          </b-badge>
        </template>

        <template #cell(deleted_at)="data">
          <b-badge variant="light-success" v-if="!data.value">
            Sim
          </b-badge>

          <b-badge variant="light-danger" v-else>
            Excluído {{ data.value | converteData }}
          </b-badge>
        </template>

        <template #cell(email_verified_at)="data">
          <b-badge variant="light-success" v-if="data.value">
            Sim
          </b-badge>

          <b-badge variant="light-danger" v-else>
           Não
          </b-badge>
        </template>

        <template #cell(opcoes)="data">
          <b-button
              class="btn-icon"
              variant="flat-secondary"
              :disabled="data.item.tipo_usuario === 1"
              @click="editarUsuario(data.item)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>

          <b-button
              class="btn-icon"
              variant="flat-danger"
              :disabled="data.item.tipo_usuario === 1"
              @click="excluirUsuario(data.item.id, data.index)"
              v-if="!data.item.deleted_at"
          >
            <feather-icon class="text-danger" icon="Trash2Icon" />
          </b-button>

          <b-button
              class="btn-icon"
              variant="flat-primary"
              @click="reativarUsuario(data.item.id)"
              v-else
          >
            Ativar
          </b-button>

        </template>
      </b-table>
    </b-col>


    <EditarUsuarioModal @cadastro="obterListaUsuarios" :editar="editar_usuario" @editar="editar_usuario = $event"/>
    <CadastrarNovoUsuario @cadastro="obterListaUsuarios"/>
  </b-row>
</template>

<script>
import {
  BAlert, BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BModal, VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditarUsuarioModal from './EditarUsuario.vue'
import CadastrarNovoUsuario from './CadastrarNovoUsuario.vue'
import moment from 'moment'


export default {
  components: {
    BAlert,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    VBModal,
    EditarUsuarioModal,
    CadastrarNovoUsuario,
  },
  directives: {
    'b-modal': VBModal,

  },
  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      boxTwo:'',
      users: [],
      editar_usuario:[],
      permissions_id: '',
      acl_permission:[],
      fields: [

        { key: 'url', label: 'Avatar', },
        { key: 'name', label: 'Nome', sortable: true },
        'email',
        { key: 'tipo_usuario', label: 'Tipo Usuário', sortable: true },
        { key: 'deleted_at', label: 'Ativo', sortable: true },
        { key: 'email_verified_at', label: 'Email Confirmado', sortable: true },
        { key: 'opcoes', label:'Opções', sortable:false}

      ],
      ativo: [
        {
          false: 'Não',
          true: 'Sim'
        },
        {
          false: 'light-danger',
          true: 'light-success',
        },
      ],
      permissions: [
        {
          1: 'Administrador',
          2: 'Radiologia',
          3: 'Projetos Cad',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-info',
        }],
    }
  },

  computed: {

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },

  },
  mounted() {
    // Set the initial number of items
    this.obterListaUsuarios()
    this.totalRows = this.users.length
  },
  methods: {

    reativarUsuario(id) {
      this.$http.post('odontodocs/usuarios/reativar/'+id).then( (res) => {
        this.obterListaUsuarios()
      }).catch(error => {

        let res = this.$responseMessages.getMessage(error, null)
        this.getErrors = res

      })
    },
    obterListaUsuarios(){
      this.$http.get('odontodocs/usuarios').then(res => {
        this.users = res.data
      })
    },
    atualizaLista(){
      this.obterListaUsuarios()
    },

    excluirUsuario(id, index){

      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Não poderá ser revertido a exclusão!', {
          title: 'Deseja realmente excluir esse Usuário?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then( valor =>{
          if(valor){
            this.$http.delete('odontodocs/usuarios/'+id).then(res => {

              this.obterListaUsuarios()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Usuário excluído com sucesso!',
                  icon: 'InfoIcon',
                  variant: 'success',
                },
              })
            }).catch(error => {

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ops! Algo deu errado',
                  icon: 'InfoIcon',
                  variant: 'danger',
                },
              })
            })

          }
        })

    },

    editarUsuario(usuario){

      console.log('editar usuario', usuario)
      this.editar_usuario = usuario
      this.$bvModal.show('editarUsuario')
    }
  },
  filters: {
    converteData(data) {
      if(!data) return ''
      return moment(data).format('DD/MM/YYYY')
    }
  }

}
</script>
